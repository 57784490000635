body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-image: url('./Images/GroupFireJump.jfif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Keeps the background fixed during scrolling */
  height: 100vh; /* Ensures full-screen coverage */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
